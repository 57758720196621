<template>
  <q-card style="max-width: 100%; width: 400px">
    <q-form ref="formRef" @submit.prevent="onSubmit" autocomplete="off" greedy>
      <q-card-section class="row items-center text-h6">
        Redigera saknad avvikelse distans</q-card-section
      >

      <q-separator />

      <q-card-section class="q-pa-none"> </q-card-section>
      <div class="row">
        <div class="col-12 q-pa-md">
          <q-card bordered flat>
            <q-card-section class="q-pa-none q-px-md bg-grey-2">
              <div class="row">
                <div
                  class="col-4"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.16)"
                >
                  <q-input
                    dense
                    hide-bottom-space
                    borderless
                    square
                    :model-value="
                      deviationTypeToLocaleMap[formModal.state.data.type]
                    "
                    label="Typ"
                    readonly
                  />
                </div>
                <div
                  class="col-4 q-pl-md"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.16)"
                >
                  <q-input
                    dense
                    hide-bottom-space
                    borderless
                    square
                    :model-value="
                      format(
                        new Date(formModal.state.data.departureDate),
                        'yyyy-MM-dd'
                      )
                    "
                    label="Avgångsdatum"
                    readonly
                  />
                </div>
                <div
                  class="col-2 q-pl-md"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.16)"
                >
                  <q-input
                    dense
                    hide-bottom-space
                    borderless
                    square
                    :model-value="formModal.state.data.from"
                    label="Från"
                    readonly
                  />
                </div>
                <div class="col-2 q-pl-md">
                  <q-input
                    dense
                    hide-bottom-space
                    borderless
                    square
                    :model-value="formModal.state.data.to"
                    label="Till"
                    readonly
                  />
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
      <div class="row">
        <div class="col-12 q-pa-md">
          <q-input
            outlined
            square
            v-model.number="model.distance"
            label="Distans"
            :rules="[requiredNotLessThenZero]"
          />
        </div>
      </div>
      <q-separator />

      <q-card-actions align="right">
        <q-btn
          flat
          label="Avbryt"
          @click="formModal.closeModal"
          type="button"
          :disable="loading"
        />
        <q-btn
          label="Bekräfta"
          type="submit"
          color="primary"
          :loading="loading"
        />
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script lang="ts">
import { UseModal } from '@/composable/useModal'
import { QForm } from 'quasar'
import { defineComponent, inject, ref } from 'vue'
import { requiredNotLessThenZero } from '@/common/formValidationRules'
import { SearchDeviationMissingDistance } from '@/types/search-deviation-missing-distance'
import { format } from 'date-fns'
import { updateDeviationVehicleDistance } from '@/api/deviation-vehicle/updateDeviationVehicle'
import { updateDeviationCrewDistance } from '@/api/deviation-crew/updateDeviationCrew'
import { updateDeviationCancelDistance } from '@/api/deviation-cancel/updateDeviationCancel'
import { useDeviationMissingDistance } from '@/composable/useDeviationMissingDistance'
import { deviationTypeToLocaleMap } from '@/common/deviationTypeToLocaleMap'

export default defineComponent({
  name: 'DeviatingMissingDistanceFormModal',

  setup() {
    const { fetchAll: fetchDeviationMissingDistance } =
      useDeviationMissingDistance()
    const formModal =
      inject<UseModal<SearchDeviationMissingDistance>>('form-modal')
    const formRef = ref<QForm | null>(null)

    const model = ref<Partial<SearchDeviationMissingDistance>>({
      distance: 0,
    })
    const loading = ref(false)

    const deviationApiMapByType = {
      deviationVehicle: updateDeviationVehicleDistance,
      deviationCrew: updateDeviationCrewDistance,
      deviationCancel: updateDeviationCancelDistance,
    }

    if (typeof formModal?.state.data?.distance === 'string') {
      const distance = parseInt(formModal?.state.data?.distance, 10)
      model.value.distance = distance
    }

    const onSubmit = async () => {
      const isValid = await formRef.value?.validate()
      const type = formModal?.state.data?.type
      const uuid = formModal?.state.data?.uuid

      if (!isValid || !type || !uuid || !model.value.distance) return
      loading.value = true
      await deviationApiMapByType[type](uuid, {
        distance: model.value.distance,
      })
      await fetchDeviationMissingDistance()
      loading.value = false
      formModal?.closeModal()
    }

    return {
      loading,
      requiredNotLessThenZero,
      model,
      formModal,
      formRef,
      onSubmit,
      format,
      deviationTypeToLocaleMap,
    }
  },
})
</script>
