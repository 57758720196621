<template>
  <q-dialog v-model="formModal.state.show" :persistent="false">
    <DeviatingMissingDistanceFormModal v-if="formModal.state.show" />
  </q-dialog>
  <q-table
    dense
    :class="$style.stickyHeader"
    :loading="loading.getAll"
    :filter="filterText"
    :rows-per-page-options="[0]"
    :rows="deviationMissingDistance"
    :columns="columns"
    row-key="uuid"
    style="height: calc(100vh - 50px - 68px)"
    flat
  >
    <template v-slot:top>
      <h6 class="q-ma-none">Avvikelser som saknar distans</h6>

      <q-space />

      <q-input
        style="width: 100%; max-width: 300px"
        debounce="300"
        v-model="filterText"
        placeholder="Sök..."
        dense
        outlined
      >
        <template v-slot:append>
          <q-icon name="mdi-magnify" />
        </template>
      </q-input>

      <q-btn
        :loading="exporting"
        @click="onExport"
        class="q-ml-md"
        icon-right="mdi-file-export-outline"
        label="Export"
        color="grey-7"
        outline
      />
    </template>

    <template v-slot:body-cell-_action="props">
      <q-td :props="props">
        <q-btn-dropdown
          size="sm"
          flat
          round
          color="grey-7"
          dropdown-icon="mdi-dots-vertical"
          content-class="no-border-radius"
        >
          <q-list>
            <q-item
              clickable
              v-close-popup
              @click="actionItem.action(props.row)"
              v-for="actionItem in actionItems"
              :key="actionItem.label"
            >
              <q-item-section avatar>
                <q-icon size="xs" :name="actionItem.icon" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ actionItem.label }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </q-td>
    </template>
  </q-table>
</template>

<script lang="ts">
import { computed, defineComponent, provide, ref } from 'vue'
import { QTable } from 'quasar'
import { format } from 'date-fns'
import { useProfile } from '@/composable/useProfile'
import { exportExcel } from '@/services/export-excel'
import { useDeviationMissingDistance } from '@/composable/useDeviationMissingDistance'
import { SearchDeviationMissingDistance } from '@/types/search-deviation-missing-distance'
import { useModal } from '@/composable/useModal'
import DeviatingMissingDistanceFormModal from './DeviatingMissingDistanceFormModal.vue'
import { deviationTypeToLocaleMap } from '@/common/deviationTypeToLocaleMap'

export default defineComponent({
  name: 'DeviatingMissingDistanceTable',

  components: {
    DeviatingMissingDistanceFormModal,
  },

  setup() {
    const {
      fetchAll: fetchDeviationMissingDistance,
      data: deviationMissingDistance,
      loading,
    } = useDeviationMissingDistance()
    const { can } = useProfile()
    const exporting = ref(false)
    const formModal = useModal()
    provide('form-modal', formModal)

    fetchDeviationMissingDistance()

    const columns: QTable['columns'] = [
      {
        align: 'left',
        name: 'type',
        label: 'Typ',
        field: 'type',
        sortable: true,
        format: (type: SearchDeviationMissingDistance['type']) =>
          deviationTypeToLocaleMap[type],
      },
      {
        align: 'left',
        name: 'departureDate',
        label: 'Avgångsdatum',
        field: 'departureDate',
        sortable: true,
        format: (v: string) => format(new Date(v), 'yyyy-MM-dd'),
      },
      {
        align: 'left',
        name: 'from',
        label: 'Från',
        field: 'from',
        sortable: true,
      },
      {
        align: 'left',
        name: 'to',
        label: 'Till',
        field: 'to',
        sortable: true,
      },
      {
        align: 'left',
        name: 'distance',
        label: 'Distans',
        field: 'distance',
        sortable: true,
      },
      {
        align: 'right',
        name: '_action',
        label: '',
        field: '_action',
        sortable: false,
      },
    ]

    async function onExport() {
      exporting.value = true

      const exportData = {
        name: 'Avvikelser som saknar distans',
        sheets: [
          {
            name: 'Avvikelser som saknar distans',
            data: deviationMissingDistance.value.map((item) => {
              return columns?.reduce<{ [name: string]: unknown }>(
                (acc, col) => {
                  const value =
                    typeof col.field === 'function'
                      ? col.field(item)
                      : item[col.name as keyof SearchDeviationMissingDistance]

                  if (col.format) {
                    acc[col.label] = col.format(value, null)
                  } else {
                    acc[col.label] = value
                  }

                  return acc
                },
                {}
              )
            }),
          },
        ],
      }

      exportExcel(exportData)
      exporting.value = false
    }

    const actionEdit = (row: Record<string, unknown>) => {
      formModal?.openModal({
        mode: 'update',
        data: row,
      })
    }

    const actionItems = computed(() => [
      {
        icon: 'mdi-pencil',
        label: 'Redigera',
        action: actionEdit,
      },
    ])

    return {
      columns,
      loading,
      deviationMissingDistance,
      exporting,
      can,
      onExport,
      filterText: ref(''),
      actionItems,
      formModal,
    }
  },
})
</script>

<style lang="scss" module>
.stickyHeader {
  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    background-color: white;
  }

  thead tr th {
    position: sticky;
    z-index: 1;
  }

  thead tr:first-child th {
    top: 0;
  }
}
</style>
