
import { defineComponent } from 'vue'
import DeviatingMissingDistanceTable from '@/components/tools/deviating-missing-distance/DeviatingMissingDistanceTable.vue'

export default defineComponent({
  name: 'DeviatingMissingDistance',

  components: {
    DeviatingMissingDistanceTable,
  },

  setup() {
    return {}
  },
})
