import { render } from "./DeviatingMissingDistanceTable.vue?vue&type=template&id=77de7f68"
import script from "./DeviatingMissingDistanceTable.vue?vue&type=script&lang=ts"
export * from "./DeviatingMissingDistanceTable.vue?vue&type=script&lang=ts"

const cssModules = script.__cssModules = {}
import style0 from "./DeviatingMissingDistanceTable.vue?vue&type=style&index=0&id=77de7f68&lang=scss&module=true"
cssModules["$style"] = style0
script.render = render

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QTable,QSpace,QInput,QIcon,QBtn,QTd,QBtnDropdown,QList,QItem,QItemSection,QItemLabel});qInstall(script, 'directives', {ClosePopup});
