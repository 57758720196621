import { Ref, ref, ComputedRef, computed } from 'vue'
import { useLoading, Loading } from '@/composable/useLoading'
import { SearchDeviationMissingDistance } from '@/types/search-deviation-missing-distance'
import { getDeviationMissingDistance } from '@/api/search/deviation-missing-distance/getDeviationMissingDistance'

interface UseDeviationMissingDistance {
  data: Ref<SearchDeviationMissingDistance[]>
  fetchAll: () => Promise<SearchDeviationMissingDistance[]>
  loading: ComputedRef<Loading>
}

const data = ref<SearchDeviationMissingDistance[]>([])
const { state } = useLoading()

export function useDeviationMissingDistance(): UseDeviationMissingDistance {
  async function fetchAll() {
    state.getAll = true
    return new Promise<SearchDeviationMissingDistance[]>((resolve, reject) => {
      getDeviationMissingDistance()
        .then(({ data: deviationMissingDistance }) => {
          data.value = deviationMissingDistance
          resolve(deviationMissingDistance)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  return {
    data,
    fetchAll,
    loading: computed(() => state),
  }
}
