
import { computed, defineComponent, provide, ref } from 'vue'
import QTable from 'quasar/src/components/table/QTable.js';
import { format } from 'date-fns'
import { useProfile } from '@/composable/useProfile'
import { exportExcel } from '@/services/export-excel'
import { useDeviationMissingDistance } from '@/composable/useDeviationMissingDistance'
import { SearchDeviationMissingDistance } from '@/types/search-deviation-missing-distance'
import { useModal } from '@/composable/useModal'
import DeviatingMissingDistanceFormModal from './DeviatingMissingDistanceFormModal.vue'
import { deviationTypeToLocaleMap } from '@/common/deviationTypeToLocaleMap'

export default defineComponent({
  name: 'DeviatingMissingDistanceTable',

  components: {
    DeviatingMissingDistanceFormModal,
  },

  setup() {
    const {
      fetchAll: fetchDeviationMissingDistance,
      data: deviationMissingDistance,
      loading,
    } = useDeviationMissingDistance()
    const { can } = useProfile()
    const exporting = ref(false)
    const formModal = useModal()
    provide('form-modal', formModal)

    fetchDeviationMissingDistance()

    const columns: QTable['columns'] = [
      {
        align: 'left',
        name: 'type',
        label: 'Typ',
        field: 'type',
        sortable: true,
        format: (type: SearchDeviationMissingDistance['type']) =>
          deviationTypeToLocaleMap[type],
      },
      {
        align: 'left',
        name: 'departureDate',
        label: 'Avgångsdatum',
        field: 'departureDate',
        sortable: true,
        format: (v: string) => format(new Date(v), 'yyyy-MM-dd'),
      },
      {
        align: 'left',
        name: 'from',
        label: 'Från',
        field: 'from',
        sortable: true,
      },
      {
        align: 'left',
        name: 'to',
        label: 'Till',
        field: 'to',
        sortable: true,
      },
      {
        align: 'left',
        name: 'distance',
        label: 'Distans',
        field: 'distance',
        sortable: true,
      },
      {
        align: 'right',
        name: '_action',
        label: '',
        field: '_action',
        sortable: false,
      },
    ]

    async function onExport() {
      exporting.value = true

      const exportData = {
        name: 'Avvikelser som saknar distans',
        sheets: [
          {
            name: 'Avvikelser som saknar distans',
            data: deviationMissingDistance.value.map((item) => {
              return columns?.reduce<{ [name: string]: unknown }>(
                (acc, col) => {
                  const value =
                    typeof col.field === 'function'
                      ? col.field(item)
                      : item[col.name as keyof SearchDeviationMissingDistance]

                  if (col.format) {
                    acc[col.label] = col.format(value, null)
                  } else {
                    acc[col.label] = value
                  }

                  return acc
                },
                {}
              )
            }),
          },
        ],
      }

      exportExcel(exportData)
      exporting.value = false
    }

    const actionEdit = (row: Record<string, unknown>) => {
      formModal?.openModal({
        mode: 'update',
        data: row,
      })
    }

    const actionItems = computed(() => [
      {
        icon: 'mdi-pencil',
        label: 'Redigera',
        action: actionEdit,
      },
    ])

    return {
      columns,
      loading,
      deviationMissingDistance,
      exporting,
      can,
      onExport,
      filterText: ref(''),
      actionItems,
      formModal,
    }
  },
})
