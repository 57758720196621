
import { UseModal } from '@/composable/useModal'
import QForm from 'quasar/src/components/form/QForm.js';
import { defineComponent, inject, ref } from 'vue'
import { requiredNotLessThenZero } from '@/common/formValidationRules'
import { SearchDeviationMissingDistance } from '@/types/search-deviation-missing-distance'
import { format } from 'date-fns'
import { updateDeviationVehicleDistance } from '@/api/deviation-vehicle/updateDeviationVehicle'
import { updateDeviationCrewDistance } from '@/api/deviation-crew/updateDeviationCrew'
import { updateDeviationCancelDistance } from '@/api/deviation-cancel/updateDeviationCancel'
import { useDeviationMissingDistance } from '@/composable/useDeviationMissingDistance'
import { deviationTypeToLocaleMap } from '@/common/deviationTypeToLocaleMap'

export default defineComponent({
  name: 'DeviatingMissingDistanceFormModal',

  setup() {
    const { fetchAll: fetchDeviationMissingDistance } =
      useDeviationMissingDistance()
    const formModal =
      inject<UseModal<SearchDeviationMissingDistance>>('form-modal')
    const formRef = ref<QForm | null>(null)

    const model = ref<Partial<SearchDeviationMissingDistance>>({
      distance: 0,
    })
    const loading = ref(false)

    const deviationApiMapByType = {
      deviationVehicle: updateDeviationVehicleDistance,
      deviationCrew: updateDeviationCrewDistance,
      deviationCancel: updateDeviationCancelDistance,
    }

    if (typeof formModal?.state.data?.distance === 'string') {
      const distance = parseInt(formModal?.state.data?.distance, 10)
      model.value.distance = distance
    }

    const onSubmit = async () => {
      const isValid = await formRef.value?.validate()
      const type = formModal?.state.data?.type
      const uuid = formModal?.state.data?.uuid

      if (!isValid || !type || !uuid || !model.value.distance) return
      loading.value = true
      await deviationApiMapByType[type](uuid, {
        distance: model.value.distance,
      })
      await fetchDeviationMissingDistance()
      loading.value = false
      formModal?.closeModal()
    }

    return {
      loading,
      requiredNotLessThenZero,
      model,
      formModal,
      formRef,
      onSubmit,
      format,
      deviationTypeToLocaleMap,
    }
  },
})
